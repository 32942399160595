import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ToastUrgency } from '@core/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotificationService {
  constructor(public snackBar: MatSnackBar) {}

  showError(customErrorMessage: string): void {
    this.snackBar.open(customErrorMessage, 'X');
  }

  //TODO: Needs to be refactored when adjusting errorhandling, see ErrorService
  showErrorCustom(customErrorMessage: string, urgency: ToastUrgency = ToastUrgency.Default): void {
    const MP_TOAST_CLASSNAME = 'mp-toast';
    this.snackBar.open(customErrorMessage, 'X', {
      panelClass: [MP_TOAST_CLASSNAME, `${MP_TOAST_CLASSNAME}-${urgency}`],
    });
  }
}
