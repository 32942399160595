export function getIntValue(paramValue: string | null | undefined): number | undefined {
  if (paramValue != null) {
    const num = parseInt(paramValue, 10);
    if (!isNaN(num)) {
      return num;
    }
  }

  return undefined;
}

export function getBooleanValue(paramValue: string | null | undefined): boolean | undefined {
  const value = paramValue?.toLowerCase();
  if (value != null) {
    return value === 'true' || value === '1';
  }
  return undefined;
}

export function getEnumValue<T extends { [K in keyof T]: number | string }>(
  enumType: T,
  paramValue: string | null | undefined,
): T[keyof T] | undefined {
  if (paramValue != null) {
    paramValue = paramValue.toLowerCase();

    for (const [key, value] of Object.entries(enumType)) {
      //  Enum-Key                             Reverse-Mapping
      if (key.toLowerCase() === paramValue || (value as string | number).toString().toLowerCase() === paramValue) {
        return value as T[keyof T];
      }
    }
  }

  return undefined;
}

export function getEnumValues<T extends { [K in keyof T]: number | string }>(
  enumType: T,
  paramValues: string[] | null | undefined,
): T[keyof T][] | undefined {
  return paramValues
    ?.map((paramValue) => getEnumValue(enumType, paramValue))
    .filter((value): value is T[keyof T] => value != null);
}

export function getArrayValue(paramValue: string[] | string | null | undefined): string[] | undefined {
  if (paramValue != null) {
    if (typeof paramValue === 'string') {
      return [paramValue];
    }

    if (Array.isArray(paramValue)) {
      return paramValue;
    }
  }
  return undefined;
}
