import { Signal } from '@angular/core';

import { LoginContext } from '@mp/kernel/auth/domain';

/**
 * Base class of a service that provides the LoginContext.
 *
 * This has to be implemented and provided by the application.
 */
export abstract class AbstractLoginContextProviderService {
  /**
   * Provides the LoginContext of the application.
   */
  abstract readonly loginContext: Signal<LoginContext>;
}
