import { Observable } from 'rxjs';

/**
 * Base class of a service that provides basic auth info.
 *
 * This has to be implemented and provided by the application.
 */
export abstract class AbstractAuthInfoService {
  /**
   * Notifies when the user has successfully logged in.
   */
  abstract readonly userSuccessfullyLoggedIn$: Observable<void>;

  /**
   * Provides the auth token associated with the current user.
   */
  abstract getAccessToken(): string;

  /**
   * Schedule a logout.
   */
  abstract scheduleLogout(): void;
}
