import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppConfigService } from './config-loader/app-config.service';
import { ApiUrlService } from './services/api-url.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  exports: [OAuthModule],
  providers: [AppConfigService, ApiUrlService],
})
export class SharedModule {}
