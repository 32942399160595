import { Injectable } from '@angular/core';

import { AbstractAuthInfoService } from '@mp/shared/util';
import { LoginContextService } from '@mpk/shared/data-access';

@Injectable({ providedIn: 'root' })
export class HangfireFacade {
  constructor(
    private readonly authInfoService: AbstractAuthInfoService,
    private readonly loginContextService: LoginContextService,
  ) {}

  getHangfireUrl(jobId?: string): string | undefined {
    const accessToken: string = this.authInfoService.getAccessToken();

    const link = new URL(self.location.origin);
    link.pathname = jobId ? `/jobs/jobs/details/${jobId}` : '/jobs';
    link.searchParams.set('t', accessToken);
    link.searchParams.set(
      'x-active-organization',
      this.loginContextService.loginContext().organizationContext.organizationId,
    );

    return link.toString();
  }
}
