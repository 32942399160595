import { Injectable } from '@angular/core';
import { Observable, concatMap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AbstractAuthInfoService } from '@mp/shared/util';

import { ConfigLoaderFacade } from './config-loader.facade';
import { AppInsightsConfig, EnvironmentConfig } from './models';

@Injectable()
export class AppConfigService {
  readonly envConfig$: Observable<EnvironmentConfig> = this.configLoaderFacade.environmentConfig$;

  readonly appInsightsConfig$: Observable<AppInsightsConfig> =
    // For security reasons app insights config should not be downloaded before user is logged in
    this.authInfoService.userSuccessfullyLoggedIn$.pipe(
      concatMap(() => this.configLoaderFacade.appInsightsConfig$),
      shareReplay(1),
    );

  constructor(
    private readonly configLoaderFacade: ConfigLoaderFacade,
    private readonly authInfoService: AbstractAuthInfoService,
  ) {}
}
