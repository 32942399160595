import { Injectable, Signal } from '@angular/core';

import { LoginContext } from '@mp/kernel/auth/domain';

import { AbstractLoginContextProviderService } from './abstract-login-context-provider.service';

/**
 * Service to access the current LoginContext.
 */
@Injectable({ providedIn: 'root' })
export class LoginContextService {
  constructor(providerService: AbstractLoginContextProviderService) {
    this.loginContext = providerService.loginContext;
  }

  /**
   * Access to the context of the current logged in user.
   */
  public readonly loginContext: Signal<LoginContext>;
}
