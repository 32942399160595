import { AuthConfig, ValidAuthConfig } from '../models';

export function validateAuthConfig(authConfig?: AuthConfig): authConfig is ValidAuthConfig {
  if (!authConfig) {
    return false;
  }

  return (
    authConfig.issuer != null &&
    authConfig.clientId != null &&
    authConfig.scope != null &&
    authConfig.responseType === 'code'
  );
}
