import { Injectable } from '@angular/core';

import { LocalStorageService } from '@core/shared/util';
import { AbstractAuthInfoService } from '@mp/shared/util';

/**
 * Service to get API urls, optionally with authorization information.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  constructor(
    private readonly authInfoService: AbstractAuthInfoService,
    private readonly storageService: LocalStorageService,
  ) {}

  getApiUrl(path: string, includeAuthorization = false): string {
    const link = new URL(path, self.location.origin);

    if (!link.pathname.startsWith('/api')) {
      link.pathname = `/api${path}`;
    }

    if (includeAuthorization) {
      const accessToken = this.authInfoService.getAccessToken();
      // TODO: This has to come out of a kernel service (e.g. profile)
      const activeOrganisationId = this.storageService.readString('activeOrganisationId', null);

      link.searchParams.set('access_token', accessToken);

      if (activeOrganisationId != null) {
        link.searchParams.set('x-active-organization', activeOrganisationId);
      }
    }

    return link.toString();
  }
}
